// extracted by mini-css-extract-plugin
export var blogPostDescriptionField = "BlogPostCategoryNewForm__blogPostDescriptionField__ArxKF";
export var buttonCreateContainer = "BlogPostCategoryNewForm__buttonCreateContainer__Sgf1W";
export var column = "BlogPostCategoryNewForm__column__kPvSW";
export var editor = "BlogPostCategoryNewForm__editor__kPks1";
export var fieldInstructions = "BlogPostCategoryNewForm__fieldInstructions__vXaZj";
export var flex = "BlogPostCategoryNewForm__flex__wOT_K";
export var flexColumn = "BlogPostCategoryNewForm__flexColumn__IGGcG";
export var flexContainer = "BlogPostCategoryNewForm__flexContainer__lMWqk";
export var gap1 = "BlogPostCategoryNewForm__gap1__lOhkb";
export var gap2 = "BlogPostCategoryNewForm__gap2__P8nTK";
export var gap3 = "BlogPostCategoryNewForm__gap3__TeCmj";
export var gap4 = "BlogPostCategoryNewForm__gap4__O8LoG";
export var gap5 = "BlogPostCategoryNewForm__gap5__M50cD";
export var grid = "BlogPostCategoryNewForm__grid__Ikj8d";
export var iconDelete = "BlogPostCategoryNewForm__iconDelete__gsmiJ";
export var iconView = "BlogPostCategoryNewForm__iconView__vjQA9";
export var labelContainer = "BlogPostCategoryNewForm__labelContainer__xIaok";
export var row = "BlogPostCategoryNewForm__row__gMHUf";